import React from "react"
import { theme } from "../Styles/theme"
import { Global, css } from "@emotion/core"
import styled from "@emotion/styled"
import { ThemeProvider } from "emotion-theming"
import Layout from "../Components/layout"
import { GlobalStyles } from "../Styles/global"
import { Link } from "gatsby"
import { Section } from "../Styles/section"
import {
  HeadingPrimary,
  HeadingTertiary,
  HeadingSecondary,
} from "../Styles/headers"
import Img from "gatsby-image"

const BlogTitle = css`
  margin-bottom: 0;
  text-align: center;
  z-index: 2;
  &::after {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    content: "";
    background: #e4eff3;
    z-index: -2;
    bottom: 10px;
    width: 150px;
    height: 2rem;
    margin: 0 auto;
    text-align: center;
  }
`

const BlogWrapper = styled.div`
  background: #f8fafb;
  width: 97%;
  max-width: 98%;
  margin: 4rem auto;
  padding: 2rem 0;
`

const BlogPagination = css`
  margin-top: 4.5rem;
  width: 95%;
  max-width: 1400px;
  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    padding-top: 0;
  }
`
const Articles = styled.ul`
  display: flex;
  max-width: 1200px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: auto;

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const ArticleCard = styled.li`
  width: 48%;
  margin: ${props => props.theme.pm.pm300} 0;

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    width: 100%;
    margin: ${props => props.theme.pm.pm300} 0;
  }
`

const ArticleTitle = css`
  margin-bottom: ${theme.pm.pm100};
  font-size: ${theme.text.text400};
  line-height: 1.5;

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    margin-bottom: ${theme.pm.pm200};
  }
`

const ArticleBanner = css`
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  box-shadow: ${theme.boxShadows.images};
  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    margin-bottom: ${theme.pm.pm200};
  }
`

const LatestArticle = styled.div`
  width: 100%;
  margin: auto;
  margin-bottom: ${props => props.theme.pm.pm300};
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    flex-direction: column;
  }
`

const LatestArticleBannerWrapper = css`
  width: 48%;
  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    width: 100%;
  }
`

const LatestArticleBanner = css`
  width: 100%;
  max-height: 200px;
  box-shadow: ${theme.boxShadows.images};
  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    margin-bottom: ${theme.pm.pm200};
  }
`

const LatestArticleRight = css`
  width: 48%;
  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    width: 100%;
  }
`

const BtnMedium = css`
  background: ${theme.colors.primary};
  padding: 10px 30px;
  color: #fff;
  display: inline-block;
  border: none;
  cursor: pointer;
  font-size: .9rem;
  box-shadow: 2px 6px 8px rgba(0, 0, 0, 0.15);
`
export default ({ data }) => {
//   const { edges: posts } = data.allMdx
//   const firstArticle = posts[0].node
  return (
      <p>hello</p>
    // <ThemeProvider theme={theme}>
    //   <Global styles={GlobalStyles} />
    //   <Layout>
    //     <BlogWrapper>
    //       <div style={{ position: "relative" }}>
    //         <HeadingPrimary css={BlogTitle}>Blog</HeadingPrimary>
    //       </div>
    //       <Section css={BlogPagination}>
    //         <LatestArticle>
    //           <div css={LatestArticleBannerWrapper}>
    //             <Img
    //               css={LatestArticleBanner}
    //               sizes={firstArticle.frontmatter.banner.childImageSharp.sizes}
    //               alt="article cover"
    //             />
    //           </div>
    //           <div css={LatestArticleRight}>
    //             <HeadingSecondary style={{ lineHeight: 1.5, marginTop: 0 }}>
    //               {firstArticle.frontmatter.title}
    //             </HeadingSecondary>
    //             <Link css={BtnMedium} to={`.${firstArticle.fields.slug}`}>
    //               View Article
    //             </Link>
    //           </div>
    //         </LatestArticle>
    //         <Articles>
    //           {posts.map(({ node }, index) => {
    //             const { title, banner } = node.frontmatter
    //             if (index !== 0) {
    //               return (
    //                 <ArticleCard key={node.id}>
    //                   <Img
    //                     css={ArticleBanner}
    //                     sizes={banner.childImageSharp.sizes}
    //                     alt="article cover"
    //                   />
    //                   <HeadingTertiary css={ArticleTitle}>
    //                     {title}
    //                   </HeadingTertiary>
    //                   <Link css={BtnMedium} to={`.${node.fields.slug}`}>
    //                     View Article
    //                   </Link>
    //                 </ArticleCard>
    //               )
    //             } else return null
    //           })}
    //         </Articles>
    //       </Section>
    //     </BlogWrapper>
    //   </Layout>
    // </ThemeProvider>
  )
}

// export const pageQuery = graphql`
//   query blogIndex {
//     allMdx(sort: { fields: frontmatter___date, order: DESC }) {
//       edges {
//         node {
//           id
//           excerpt
//           fields {
//             slug
//           }
//           frontmatter {
//             title
//             author
//             date(formatString: "MMMM Do, YYYY")
//             tags
//             slug
//             banner {
//               childImageSharp {
//                 sizes(maxWidth: 900) {
//                   ...GatsbyImageSharpSizes_withWebp
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `
